import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'

// import assetsReducer from './reducers/assets'
import authReducer from './reducers/auth'
import landlordApplicationReducer from './reducers/landlordApplication'
import tenantApplicationReducer from './reducers/tenantApplication'
import documentsReducer from './reducers/documents'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const middleware = environment === 'production'
  ? applyMiddleware(thunk)
  : composeEnhancers(applyMiddleware(thunk))

const appReducer = combineReducers({
  // assets: assetsReducer,
  auth: authReducer,
  landlordApplication: landlordApplicationReducer,
  tenantApplication: tenantApplicationReducer,
  documents: documentsReducer
})

const rootReducer = (state, action) => {
  // if (action.type === 'LOGOUT') {
  //   state = undefined
  // }
  return appReducer(state, action)
}

export default createStore(rootReducer, middleware)
