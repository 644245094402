import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

const FormError = ({ errors, submitCount }) => {
  return (
    <Fragment>
      {((Object.keys(errors).length > 0) && (submitCount > 0)) &&
        <div className='pb-2 pt-4 text-danger'>
          <span className='f-bold'>
            <span className='fas fa-exclamation-triangle pr-2' />
            Something went wrong!
          </span>
          <span className='pl-2'>Check to see if you have correctly uploaded files in all required fields.</span>
        </div>
      }
    </Fragment>
  )
}

FormError.propTypes = {
  errors: PropTypes.object,
  submitCount: PropTypes.number
}

export default FormError
