import React, { lazy, Suspense } from 'react'
import { Router, Route, Switch, Redirect } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { createBrowserHistory as createHistory } from 'history'
import PropTypes from 'prop-types'
import { Loading, NotFoundPage } from '../cdui'
import { startLogout } from '../redux/actions/auth'

import NavBar from '../components/NavBar'
import Footer from '../components/Footer'

import PublicRoute from './PublicRoute' // Only logged out users
import PrivateLandlordRoute from './PrivateLandlordRoute'
import PrivateTenantRoute from './PrivateTenantRoute'
import PrivateTenantEsignRoute from './PrivateTenantEsignRoute'
import PrivateLandloardEsignRoute from './PrivateLandlordEsignRoute'
import ScrollToTop from './ScrollToTop'
import AppWrapper from './AppWrapper'

// LANDING PAGE
const LandingPage = lazy(() => import('../components/landing/LandingPage'))

// AUTH
const SignUp = lazy(() => import('../components/auth/SignUp'))
const LogIn = lazy(() => import('../components/auth/LogIn'))
const ForgotPassword = lazy(() => import('../components/auth/ForgotPassword'))
const ResetPasswordPage = lazy(() => import('../components/auth/ResetPasswordPage'))

// LANDLORD APPLICATION
const LandLordApplication = lazy(() => import('../components/applications/LandLordApplication'))
const LandlordInformation = lazy(() => import('../components/applications/landlord/LandlordInfo'))
const LandlordDocumentsUpload = lazy(() => import('../components/applications/landlord/DocumentsUpload'))
const AddPropertyTenant = lazy(() => import('../components/applications/landlord/AddPropertyTenant'))
// const PropertyTenantDocumentsUpload = lazy(() => import('../components/applications/landlord/PropertyTenantDocumentsUpload'))
const EditPropertyTenant = lazy(() => import('../components/applications/landlord/EditPropertyTenant'))
const ESignLL = lazy(() => import('../components/applications/landlord/ESign'))
const LandlordMessageCenter = lazy(() => import('../components/applications/landlord/MessageCenter'))

// TENANT APPLICATION
const TenantApplication = lazy(() => import('../components/applications/TenantApplication'))
const TenantLandlordInformation = lazy(() => import('../components/applications/tenant/LandlordInfo'))
const HouseHoldInfo = lazy(() => import('../components/applications/tenant/HouseHoldInfo'))
const FinancialInfo = lazy(() => import('../components/applications/tenant/FinancialInfo'))
const AssistanceInfo = lazy(() => import('../components/applications/tenant/AssistanceInfo'))
const LandlordSearchResult = lazy(() => import('../components/applications/tenant/LandlordSearchResult'))
const ApplicantInformation = lazy(() => import('../components/applications/tenant/ApplicantInfo'))
const TenantDocumentsUpload = lazy(() => import('../components/applications/tenant/DocumentsUpload'))
const TenantESign = lazy(() => import('../components/applications/tenant/ESign'))
const TenantMessageCenter = lazy(() => import('../components/applications/tenant/MessageCenter'))

// STATIC
const ReportFraud = lazy(() => import('../components/static/ReportFraud'))
const SampleDocuments = lazy(() => import('../components/static/SampleDocuments'))
const Language = lazy(() => import('../components/static/Language'))

// TEST
const TestPage = lazy(() => import('../components/TestPage'))

export const history = createHistory()

const AppRouter = (props) => {
  const isLoggedIn = useSelector(state => state.auth.isLoggedIn)
  const dispatch = useDispatch()

  return (
    <Router history={history}>
      <ScrollToTop>
        <div className="container-scroller">
          <NavBar
            isLoggedIn={isLoggedIn}
            onClickLogIn={() => { history.push('/login') }}
            onClickLogOut={() => { dispatch(startLogout()) }} />

          <AppWrapper>
            <Suspense fallback={<Loading main={true} />}>
              <Switch>
                {/* LANDING */}
                <PublicRoute path='/' component={LandingPage} exact />

                {/* AUTH */}
                <PublicRoute path='/login' component={LogIn} exact />
                <PublicRoute path='/forgot-password' component={ForgotPassword} exact />
                <PublicRoute path='/reset-password/:token?' component={ResetPasswordPage} exact />

                {/* SIGN_UP */}
                <PublicRoute path='/signup/:type/:inviteCode?' component={SignUp} exact />
                {/*<PublicRoute path='/check-eligibility/:type/:inviteCode?' component={(props) => (<Redirect to={`/signup/${props.match.params.type}/${props.match.params.inviteCode}`} />)} exact />*/}

                {/* APPLICATIONS */}
                <PrivateLandlordRoute path='/application/landlord' component={LandLordApplication} exact />
                <PrivateLandlordRoute path='/application/landlord/info' component={LandlordInformation} exact />
                <PrivateLandlordRoute path='/application/landlord/documents' component={LandlordDocumentsUpload} exact />

                <PrivateLandlordRoute path='/application/landlord/tenant/new' component={AddPropertyTenant} exact />
                <PrivateLandlordRoute path='/application/landlord/tenant/:tenantID' component={EditPropertyTenant} exact />
                <PrivateLandloardEsignRoute path='/application/landlord/e-sign' component={ESignLL} exact />
                <PrivateLandlordRoute path='/application/landlord/message-center' component={LandlordMessageCenter} exact />
                {/* 3/26 requested to be removed from MS */}
                {/* <PrivateLandlordRoute path='/application/landlord/tenant/:tenantID/documents' component={PropertyTenantDocumentsUpload} exact /> */}

                <PrivateTenantRoute path='/application/tenant' component={TenantApplication} exact />
                <PrivateTenantRoute path='/application/tenant/info' component={ApplicantInformation} exact />
                <PrivateTenantRoute path='/application/tenant/landlord' component={TenantLandlordInformation} exact />
                <PrivateTenantRoute path='/application/tenant/landlord/result' component={LandlordSearchResult} exact />
                <PrivateTenantRoute path='/application/tenant/household' component={HouseHoldInfo} exact />
                <PrivateTenantRoute path='/application/tenant/financial' component={FinancialInfo} exact />
                <PrivateTenantRoute path='/application/tenant/assistance' component={AssistanceInfo} exact />
                <PrivateTenantRoute path='/application/tenant/documents' component={TenantDocumentsUpload} exact />
                <PrivateTenantRoute path='/application/tenant/message-center' component={TenantMessageCenter} exact />
                <PrivateTenantEsignRoute path='/application/tenant/e-sign' component={TenantESign} exact />

                {/* STATIC */}
                <Route path='/report-fraud' component={ReportFraud} exact />
                <Route path='/sample-documents' component={SampleDocuments} exact />
                <Route path='/languages/:type' component={Language} exact />

                {/* TEST */}
                {environment === 'development' && <Route path='/test' component={TestPage} exact />}

                <Route component={() => <NotFoundPage onClick={() => { history.push('/') }} />} />
              </Switch>
            </Suspense>

          </AppWrapper>
          <Footer />
        </div>
      </ScrollToTop>
    </Router>
  )
}

AppRouter.propTypes = {
}

export default AppRouter
