import axios from '../../utils/axios'
import ls from 'local-storage'
import { history } from '../../routers/AppRouter'
import { toast } from 'react-toastify'

// DEFINITIONS
export const AUTH_BEGIN = 'AUTH_BEGIN'
export const AUTH_END = 'AUTH_END'

export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'
export const SIGN_UP = 'SIGN_UP'
export const SEND_FORGOT_PASSWORD_EMAIL = 'SEND_FORGOT_PASSWORD_EMAIL'
export const RESET_PASSWORD = 'RESET_PASSWORD'
export const REPORT_FRAUD = 'REPORT_FRAUD'
export const CHECK_ZIP_CODE = 'CHECK_ZIP_CODE'

// ACTION CREATORS
export const authBegin = () => ({ type: AUTH_BEGIN })
export const authEnd = () => ({ type: AUTH_END })

/* AHTU */
export const login = (currentUser = {}) => ({ type: LOGIN, currentUser })
export const logout = () => ({ type: LOGOUT })
export const signUp = (currentUser) => ({ type: SIGN_UP, currentUser })
export const sendForgotPasswordEmail = () => ({ type: SEND_FORGOT_PASSWORD_EMAIL })
export const resetPassword = () => ({ type: RESET_PASSWORD })

/* REPORT FRAUD */
export const sendReportFraud = () => ({ type: REPORT_FRAUD })

/* CHECK ZIP CODE */
export const checkZipCode = (isZipCodeValid) => ({ type: CHECK_ZIP_CODE, isZipCodeValid })

// LOGIN
export const startLogin = (payload, setSubmitting) => {
  return async (dispatch, getState) => {
    dispatch(authBegin())
    try {
      const response = await axios.post(`${baseURL}/login`, payload)
      const { token, ...user } = response.data
      ls('user', user)
      dispatch(login(user))
      if (user.isLandlord) {
        history.push('/application/landlord')
      }
      if (user.isTenant) {
        history.push('/application/tenant')
      }
    } catch (error) {
      console.log(error.response)
      dispatch(authEnd())
      setSubmitting()
      toast.error('It looks like something went wrong. Please try again.')
    }
  }
}

// LOGOUT
export const startLogout = () => {
  return (dispatch, getState) => {
    ls.clear()
    dispatch(logout())
    history.push('/')
  }
}

// SIGN UP
export const startSignUp = (payload, setSubmitting) => {
  return async (dispatch, getState) => {
    dispatch(authBegin())
    try {
      const response = await axios.post(`${baseURL}/register`, payload)
      const { token, ...user } = response.data
      ls('user', user)
      await dispatch(signUp(user))
      payload.type === 'landlord' && history.push('/application/landlord')
      payload.type === 'tenant' && history.push('/application/tenant')
    } catch (error) {
      console.log(error.response)
      dispatch(authEnd())
      setSubmitting()
      toast.error('It looks like something went wrong. Please try again.')
    }
  }
}

// SEND_FORGOT_PASSWORD_EMAIL
export const startSendForgotPasswordEmail = (payload, setSubmitting, cb) => {
  return async (dispatch, getState) => {
    dispatch(authBegin())
    try {
      const response = await axios.post(`${baseURL}/sendVerificationEmail`, payload)
      setSubmitting()
      dispatch(sendForgotPasswordEmail())
      cb()
    } catch (error) {
      setSubmitting()
      console.log(error.response)
      dispatch(authEnd())
      toast.error('It looks like something went wrong. Please try again.')
    }
  }
}

// RESET_PASSWORD
export const startResetPassword = (payload, setSubmitting) => {
  return async (dispatch, getState) => {
    dispatch(authBegin())
    try {
      const response = await axios.post(`${baseURL}/resetPassword`, payload)
      dispatch(resetPassword())
      ls.clear()
      toast.success('Password updated. Please log back in.')
      history.push('/login')
    } catch (error) {
      setSubmitting()
      console.log(error.response)
      dispatch(authEnd())
      toast.error('It looks like something went wrong. Please try again.')
    }
  }
}

// REPORT_FRAUD
export const startSendReportFraud = (payload, setSubmitting) => {
  return async (dispatch, getState) => {
    dispatch(authBegin())
    try {
      const response = await axios.post(`${baseURL}/reportFraud`, payload)
      dispatch(sendReportFraud())
      toast.success('Thank you. Your fraud report has been submitted.')
      history.push('/')
    } catch (error) {
      setSubmitting()
      console.log(error.response)
      dispatch(authEnd())
      toast.error('It looks like something went wrong. Please try again.')
    }
  }
}

// CHECK_ZIP_CODE
export const startCheckZipCode = (zip, setSubmitting) => {
  return async (dispatch, getState) => {
    dispatch(authBegin())
    try {
      const response = await axios.get(`${baseURL}/allowedGeolocation/${zip}`)
      const { isRestricted } = response.data
      if (isRestricted) {
        toast.error('Your zip code is not eligible for assistance under the RAMP program.')
        setSubmitting()
      } else {
        dispatch(checkZipCode(true))
      }
    } catch (error) {
      setSubmitting()
      console.log(error.response)
      dispatch(authEnd())
      toast.error('It looks like something went wrong. Please try again.')
    }
  }
}
