import {
  DOCUMENTS_BEGIN,
  DOCUMENTS_END,
  GET_TEMPLATE_DOCUMENT,
  DOWNLOAD_TEMPLATE_DOCUMENT,
  UPLOAD_LANDLORD_DOCUMENT,
  DELETE_LANDLORD_DOCUMENT,
  DOWNLOAD_LANDLORD_DOCUMENT,
  DELETE_RENTAL_PROPERTY_TENANT_DOCUMENT,
  UPLOAD_RENTAL_PROPERTY_TENANT_DOCUMENT,
  DOWNLOAD_RENTAL_PROPERTY_TENANT_DOCUMENT,
  UPLOAD_TENANT_DOCUMENT,
  DELETE_TENANT_DOCUMENT,
  DOWNLOAD_TENANT_DOCUMENT,
  UPLOAD_ADDITIONAL_TENANT_DOCUMENT,
  UPLOAD_UTILITY_TENANT_DOCUMENT,
  GET_TENANT_DOCUMENT_LIST,
  GET_LANDLORD_DOCUMENT_LIST,
  UPLOAD_ADDITIONAL_LANDLORD_DOCUMENT,
  DOWNLOAD_ADDITIONAL_LANDLORD_DOCUMENT
} from '../actions/documents'

const initialState = {
  templateDocuments: {},
  tenantDocumentList: [],
  landlordDocumentList: [],
  loading: false
}

const documentsReducer = (state = initialState, action) => {
  console.log('Reducer: ', action)
  const { type, templateDocument, tenantDocumentList, landlordDocumentList } = action
  switch (type) {
    case DOCUMENTS_BEGIN:
      return { ...state, loading: true }

    case DOCUMENTS_END:
    case UPLOAD_LANDLORD_DOCUMENT:
    case DELETE_LANDLORD_DOCUMENT:
    case DOWNLOAD_LANDLORD_DOCUMENT:
    case DOWNLOAD_TEMPLATE_DOCUMENT:
    case UPLOAD_RENTAL_PROPERTY_TENANT_DOCUMENT:
    case DOWNLOAD_RENTAL_PROPERTY_TENANT_DOCUMENT:
    case DELETE_RENTAL_PROPERTY_TENANT_DOCUMENT:
    case UPLOAD_TENANT_DOCUMENT:
    case DELETE_TENANT_DOCUMENT:
    case DOWNLOAD_TENANT_DOCUMENT:
    case UPLOAD_ADDITIONAL_TENANT_DOCUMENT:
    case UPLOAD_UTILITY_TENANT_DOCUMENT:
    case UPLOAD_ADDITIONAL_LANDLORD_DOCUMENT:
    case DOWNLOAD_ADDITIONAL_LANDLORD_DOCUMENT:
      return { ...state, loading: false }

    case GET_TEMPLATE_DOCUMENT:
      return { ...state, templateDocuments: templateDocument, loading: false }

    case GET_TENANT_DOCUMENT_LIST:
      return { ...state, tenantDocumentList, loading: false }

    case GET_LANDLORD_DOCUMENT_LIST:
      return { ...state, landlordDocumentList, loading: false }

    default:
      return state
  }
}

export default documentsReducer
