import {
  TENANT_APP_BEGIN,
  TENANT_APP_END,

  GET_TENANT_APPLICANT_INFO,
  UPDATE_TENANT_APPLICANT_INFO,
  SIGN_TENANT,
  GET_LL_SEARCH,
  INVITE_LANDLORD,

  GET_HOUSEHOLD_MEMBERS,
  GET_HOUSEHOLD_MEMBER,
  ADD_HOUSEHOLD_MEMBER,
  UPDATE_HOUSEHOLD_MEMBER,
  DELETE_HOUSEHOLD_MEMBER,

  GET_PROGRAMS,
  GET_PROGRAM,
  ADD_PROGRAM,
  UPDATE_PROGRAM,
  DELETE_PROGRAM,

  GET_UTILITIES,
  GET_UTILITY,
  ADD_UTILITY,
  UPDATE_UTILITY,
  DELETE_UTILITY,

  GET_INCOME,
  ADD_INCOME,
  UPDATE_INCOME,
  DELETE_INCOME,

  SET_ESIGN_READY,
  GET_APP_STATUS,
  GET_COUNTIES,

  GET_MESSAGES,
  SEND_MESSAGE,
  UPDATE_MESSAGE
} from '../actions/tenantApplication'

const initialState = {
  profile: {},
  householdMembers: [],
  programs: [],
  utilities: [],
  landlords: [],
  isEsignReady: false,
  loading: false,
  status: {
    status: '',
    message: '',
    payments: [],
    submitted: ''
  },
  income: [],
  messages: []
}

const landlordApplicationReducer = (state = initialState, action) => {
  const { type, tenant, householdMembers, programs, utilities, landlords, isEsignReady, status, income, counties, messages } = action
  switch (type) {
    case TENANT_APP_BEGIN:
      return { ...state, loading: true }

    case TENANT_APP_END:
    case SIGN_TENANT:

    case GET_HOUSEHOLD_MEMBER:
    case ADD_HOUSEHOLD_MEMBER:
    case UPDATE_HOUSEHOLD_MEMBER:
    case DELETE_HOUSEHOLD_MEMBER:

    case GET_PROGRAM:
    case ADD_PROGRAM:
    case UPDATE_PROGRAM:
    case DELETE_PROGRAM:

    case GET_UTILITY:
    case ADD_UTILITY:
    case UPDATE_UTILITY:
    case DELETE_UTILITY:

    case ADD_INCOME:
    case UPDATE_INCOME:
    case DELETE_INCOME:

    case UPDATE_TENANT_APPLICANT_INFO:
    case INVITE_LANDLORD:

    case SEND_MESSAGE:
    case UPDATE_MESSAGE:
      return { ...state, loading: false }

    case GET_LL_SEARCH:
      return { ...state, loading: false, landlords }

    case GET_TENANT_APPLICANT_INFO:
      return { ...state, loading: false, profile: tenant }

    case GET_HOUSEHOLD_MEMBERS:
      return { ...state, loading: false, householdMembers }

    case GET_PROGRAMS:
      return { ...state, loading: false, programs }

    case GET_UTILITIES:
      return { ...state, loading: false, utilities }

    case SET_ESIGN_READY:
      return { ...state, loading: false, isEsignReady }

    case GET_APP_STATUS:
      return { ...state, loading: false, status }

    case GET_INCOME:
      return { ...state, loading: false, income }

    case GET_COUNTIES:
      return { ...state, loading: false, counties }
  
    case GET_MESSAGES:
      return { ...state, loading: false, messages }

    default:
      return state
  }
}

export default landlordApplicationReducer
