import React, { useState, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Collapse } from 'reactstrap'

const CollapsibleText = ({ children, defaultOpen = false }) => {
  const [isOpen, toggleOpen] = useState(defaultOpen)
  return (
    <Fragment>
      <Collapse isOpen={isOpen} className='collapse-nav'>
        {children}
      </Collapse>
      <div className='py-2'>
        <span onClick={() => toggleOpen(!isOpen)} >{isOpen ? <span className='text-link underline'>Collapse Description</span> : <span className='text-link underline text'>See Description</span>}</span>
      </div>
    </Fragment>
  )
}

CollapsibleText.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element
  ]),
  defaultOpen: PropTypes.bool
}

export default CollapsibleText
