import {
  AUTH_BEGIN,
  AUTH_END,
  LOGIN,
  LOGOUT,
  SIGN_UP,
  SEND_FORGOT_PASSWORD_EMAIL,
  RESET_PASSWORD,
  REPORT_FRAUD,
  CHECK_ZIP_CODE
} from '../actions/auth'

const initialState = {
  currentUser: {},
  isLoggedIn: false,
  loading: false,
  isZipCodeValid: false
}

const authReducer = (state = initialState, action) => {
  console.log('Reducer: ', action)
  const { type, currentUser, isZipCodeValid } = action
  switch (type) {
    case AUTH_BEGIN:
      return { ...state, loading: true }

    case AUTH_END:
    case SEND_FORGOT_PASSWORD_EMAIL:
    case REPORT_FRAUD:
      return { ...state, loading: false }

    case RESET_PASSWORD: //! check this logic
      return initialState

    case CHECK_ZIP_CODE:
      return { ...state, isZipCodeValid }

    case SIGN_UP:
    case LOGIN:
      return { ...state, currentUser, isLoggedIn: true, loading: false }

    case LOGOUT:
      return { ...state, currentUser: {}, isLoggedIn: false, isPassedPrescreening: false, loading: false }

    default:
      return state
  }
}

export default authReducer
