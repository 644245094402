import axios from '../../utils/axios'
import { toast } from 'react-toastify'
import { history } from '../../routers/AppRouter'
import { startGetLandlordProfile } from './landlordApplication'
import { startGetTenantProfile } from './tenantApplication'

// DEFINITIONS
export const DOCUMENTS_BEGIN = 'DOCUMENTS_BEGIN'
export const DOCUMENTS_END = 'DOCUMENTS_END'

export const GET_TEMPLATE_DOCUMENT = 'GET_TEMPLATE_DOCUMENT'
export const DOWNLOAD_TEMPLATE_DOCUMENT = 'DOWNLOAD_TEMPLATE_DOCUMENT'

export const UPLOAD_LANDLORD_DOCUMENT = 'UPLOAD_LANDLORD_DOCUMENT'
export const DELETE_LANDLORD_DOCUMENT = 'DELETE_LANDLORD_DOCUMENT'
export const DOWNLOAD_LANDLORD_DOCUMENT = 'DOWNLOAD_LANDLORD_DOCUMENT'

export const UPLOAD_RENTAL_PROPERTY_TENANT_DOCUMENT = 'UPLOAD_RENTAL_PROPERTY_TENANT_DOCUMENT'
export const DOWNLOAD_RENTAL_PROPERTY_TENANT_DOCUMENT = 'DOWNLOAD_RENTAL_PROPERTY_TENANT_DOCUMENT'
export const DELETE_RENTAL_PROPERTY_TENANT_DOCUMENT = 'DELETE_RENTAL_PROPERTY_TENANT_DOCUMENT'

export const UPLOAD_TENANT_DOCUMENT = 'UPLOAD_TENANT_DOCUMENT'
export const DELETE_TENANT_DOCUMENT = 'DELETE_TENANT_DOCUMENT'
export const DOWNLOAD_TENANT_DOCUMENT = 'DOWNLOAD_TENANT_DOCUMENT'

export const UPLOAD_ADDITIONAL_TENANT_DOCUMENT = 'UPLOAD_ADDITIONAL_TENANT_DOCUMENT'
export const UPLOAD_UTILITY_TENANT_DOCUMENT = 'UPLOAD_UTILITY_TENANT_DOCUMENT'
export const GET_TENANT_DOCUMENT_LIST = 'GET_TENANT_DOCUMENT_LIST'

export const UPLOAD_ADDITIONAL_LANDLORD_DOCUMENT = 'UPLOAD_ADDITIONAL_LANDLORD_DOCUMENT'
export const DOWNLOAD_ADDITIONAL_LANDLORD_DOCUMENT = 'DOWNLOAD_ADDITIONAL_LANDLORD_DOCUMENT'

export const GET_LANDLORD_DOCUMENT_LIST = 'GET_LANDLORD_DOCUMENT_LIST'

// ACTION CREATORS
export const documentsBegin = () => ({ type: DOCUMENTS_BEGIN })
export const documentsEnd = () => ({ type: DOCUMENTS_END })

export const getTemplateDocument = (templateDocument) => ({ type: GET_TEMPLATE_DOCUMENT, templateDocument })
export const downloadTemplateDocument = () => ({ type: GET_TEMPLATE_DOCUMENT })

export const uploadLandlordDocument = () => ({ type: UPLOAD_LANDLORD_DOCUMENT })
export const deleteLandlordDocument = () => ({ type: DELETE_LANDLORD_DOCUMENT })
export const downloadLandlordDocument = () => ({ type: DOWNLOAD_LANDLORD_DOCUMENT })

export const uploadRentalPropertyTenantDocument = () => ({ type: UPLOAD_RENTAL_PROPERTY_TENANT_DOCUMENT })
export const downloadRentalPropertyTenantDocument = () => ({ type: DOWNLOAD_RENTAL_PROPERTY_TENANT_DOCUMENT })
export const deleteRentalPropertyTenantDocument = () => ({ type: DELETE_RENTAL_PROPERTY_TENANT_DOCUMENT })

export const uploadTenantDocument = () => ({ type: UPLOAD_TENANT_DOCUMENT })
export const deleteTenantDocument = () => ({ type: DELETE_TENANT_DOCUMENT })
export const downloadTenantDocument = () => ({ type: DOWNLOAD_TENANT_DOCUMENT })

export const uploadAdditionalTenantDocument = () => ({ type: UPLOAD_ADDITIONAL_TENANT_DOCUMENT })
export const uploadUtilityTenantDocument = () => ({ type: UPLOAD_UTILITY_TENANT_DOCUMENT })
export const getTenantDocumentList = (tenantDocumentList) => ({ type: GET_TENANT_DOCUMENT_LIST, tenantDocumentList })

export const getLandlordDocumentList = (landlordDocumentList) => ({ type: GET_LANDLORD_DOCUMENT_LIST, landlordDocumentList })
export const uploadAdditionalLandlordDocument = () => ({ type: UPLOAD_ADDITIONAL_LANDLORD_DOCUMENT })
export const downloadAdditionalLandlordDocument = () => ({ type: DOWNLOAD_ADDITIONAL_LANDLORD_DOCUMENT })

// GET_TEMPLATE_DOCUMENT
export const startGetTemplateDocument = (documentId) => {
  return async(dispatch, getState) => {
    dispatch(documentsBegin())
    try {
      const response = await axios.get(`${baseURL}/documents/${documentId}`)
      // {
      //   "documentURL": "https://cd-generic-stage-public-docs.s3-us-west-1.amazonaws.com/W-9.pdf"
      // }

      const documents = getState().documents.templateDocuments
      const updated = {
        ...documents,
        [documentId]: response.data.documentURL
      }
      dispatch(getTemplateDocument(updated))
    } catch (error) {
      console.log(error.response)
      dispatch(documentsEnd())
    }
  }
}

// UPLOAD_LANDLORD_DOCUMENT
// export const startUploadLandlordDocument = (payload, type) => {
//   return async(dispatch, getState) => {
//     dispatch(documentsBegin())
//     try {
//       const landlordProfileID = getState().landlordApplication.profile.landlordProfileID
//       const config = { headers: { 'content-type': 'multipart/form-data' } }
//       const formData = new FormData()
//       formData.append('documentFile', payload)
//       const response = await axios.post(`${baseURL}/landlordProfiles/${landlordProfileID}/${type}`, formData, config)
//       dispatch(uploadLandlordDocument())
//       history.push('/application/landlord/documents')
//     } catch (error) {
//       console.log(error.response)
//       dispatch(documentsEnd())
//     }
//   }
// }

// UPLOAD_LANDLORD_DOCUMENT - New logic
export const startUploadLandlordDocument = (payload) => {
  return async(dispatch, getState) => {
    dispatch(documentsBegin())
    try {
      const landlordProfileID = getState().landlordApplication.profile.landlordProfileID
      const config = { headers: { 'content-type': 'multipart/form-data' } }
      for (const type in payload) {
        const formData = new FormData()
        formData.append('documentFile', payload[type])
        const response = await axios.post(`${baseURL}/landlordProfiles/${landlordProfileID}/${type}`, formData, config)
      }
      dispatch(uploadLandlordDocument())
      dispatch(startGetLandlordProfile())
      toast.success('Documents Successfully Uploaded!')
      history.push('/application/landlord')
    } catch (error) {
      console.log(error.response)
      dispatch(documentsEnd())
    }
  }
}

// DOWNLOAD_LANDLORD_DOCUMENT
export const startDownloadLandlordDocument = (documentId) => {
  return async(dispatch, getState) => {
    dispatch(documentsBegin())
    try {
      const response = await axios({ url: `${baseURL}/landlordProfiles/documents/${documentId}`, method: 'GET', responseType: 'blob' })
      const a = document.createElement('a')
      const fileName = response.headers['content-disposition'].split('filename=')[1]
      a.download = decodeURI(fileName)
      a.href = window.URL.createObjectURL(new Blob([response.data]))
      document.body.append(a)
      a.click()
      dispatch(downloadLandlordDocument(response.data))
    } catch (error) {
      console.log(error.response)
      dispatch(documentsEnd())
    }
  }
}

// DELETE_LANDLORD_DOCUMENT
export const startDeleteLandlordDocument = (documentId) => {
  return async(dispatch, getState) => {
    dispatch(documentsBegin())
    try {
      const response = await axios.delete(`${baseURL}/landlordProfiles/documents/${documentId}`)
      dispatch(deleteLandlordDocument())
      history.push('/application/landlord/documents')
      toast.success('Documents Successfully Deleted!')
    } catch (error) {
      console.log(error.response)
      dispatch(documentsEnd())
    }
  }
}

// UPLOAD_RENTAL_PROPERTY_TENANT_DOCUMENT
export const startUploadRentalPropertyTenantDocument = (payload) => {
  return async(dispatch, getState) => {
    dispatch(documentsBegin())
    try {
      const rentalPropertyTenantID = getState().landlordApplication.tenant.rentalPropertyTenantID
      const config = { headers: { 'content-type': 'multipart/form-data' } }
      for (const type in payload) {
        const formData = new FormData()
        formData.append('documentFile', payload[type])
        const response = await axios.post(`${baseURL}/rentalPropertyTenants/${rentalPropertyTenantID}/${type}`, formData, config)
      }
      dispatch(uploadRentalPropertyTenantDocument())
      toast.success('Documents Successfully Uploaded!')
      history.push(`/application/landlord`)
    } catch (error) {
      console.log(error.response)
      dispatch(documentsEnd())
    }
  }
}

// DOWNLOAD_RENTAL_PROPERTY_TENANT_DOCUMENT
export const startDownloadRentalPropertyTenantDocument = (documentID) => {
  return async(dispatch, getState) => {
    dispatch(documentsBegin())
    try {
      const response = await axios({ url: `${baseURL}/rentalPropertyTenants/documents/${documentID}`, method: 'GET', responseType: 'blob' })
      const a = document.createElement('a')
      const fileName = response.headers['content-disposition'].split('filename=')[1]
      a.download = decodeURI(fileName)
      a.href = window.URL.createObjectURL(new Blob([response.data]))
      document.body.append(a)
      a.click()
      dispatch(downloadRentalPropertyTenantDocument(response.data))
    } catch (error) {
      console.log(error.response)
      dispatch(documentsEnd())
    }
  }
}

// DELETE_LANDLORD_DOCUMENT
export const startDeleteRentalPropertyTenantDocument = (documentId, rentalPropertyTenantID) => {
  return async(dispatch, getState) => {
    dispatch(documentsBegin())
    try {
      const response = await axios.delete(`${baseURL}/rentalPropertyTenants/documents/${documentId}`)
      dispatch(deleteRentalPropertyTenantDocument())
      history.push(`/application/landlord/tenant/${rentalPropertyTenantID}/documents`)
      toast.success('Documents Successfully Deleted!')
    } catch (error) {
      console.log(error.response)
      dispatch(documentsEnd())
    }
  }
}

// UPLOAD_TENANT_DOCUMENT
export const startUploadTenantDocument = (payload) => {
  return async(dispatch, getState) => {
    dispatch(documentsBegin())
    try {
      const tenantProfileID = getState().tenantApplication.profile.tenantProfileID
      const config = { headers: { 'content-type': 'multipart/form-data' } }
      for (const type in payload) {
        const files = payload[type]
        for (let i = 0; i < files.length; i++) {
          const formData = new FormData()
          formData.append('documentFile', files[i])
          const response = await axios.post(`${baseURL}/tenantProfile/${tenantProfileID}/${type}`, formData, config)
        }
      }
      dispatch(uploadTenantDocument())
      dispatch(startGetTenantProfile())
      toast.success('Documents Successfully Uploaded!')
      history.push('/application/tenant')
    } catch (error) {
      console.log(error.response)
      dispatch(documentsEnd())
    }
  }
}

// UPLOAD_UTILITY_TENANT_DOCUMENT
export const startUploadUtilityDocument = (file) => {
  return async(dispatch, getState) => {
    dispatch(documentsBegin())
    try {
      const tenantProfileID = getState().tenantApplication.profile.tenantProfileID
      const config = { headers: { 'content-type': 'multipart/form-data' } }
      const formData = new FormData()
      formData.append('documentFile', file)
      const response = await axios.post(`${baseURL}/tenantProfile/${tenantProfileID}/UTILITY`, formData, config)
      dispatch(uploadUtilityTenantDocument())
      dispatch(startGetTenantProfile())
      dispatch(startGetDocumentList())
      toast.success('Utility Document Successfully Uploaded!')
    } catch (error) {
      console.log(error.response)
      dispatch(documentsEnd())
    }
  }
}

// DOWNLOAD_TENANT_DOCUMENT
export const startDownloadTenantDocument = (documentId) => {
  return async(dispatch, getState) => {
    dispatch(documentsBegin())
    try {
      const response = await axios({ url: `${baseURL}/tenantProfile/documents/${documentId}`, method: 'GET', responseType: 'blob' })
      const a = document.createElement('a')
      const fileName = response.headers['content-disposition'].split('filename=')[1]
      a.download = decodeURI(fileName)
      a.href = window.URL.createObjectURL(new Blob([response.data]))
      document.body.append(a)
      a.click()
      dispatch(downloadTenantDocument(response.data))
    } catch (error) {
      console.log(error.response)
      dispatch(documentsEnd())
    }
  }
}

// DELETE_TENANT_DOCUMENT
export const startDeleteTenantDocument = (documentId) => {
  return async(dispatch, getState) => {
    dispatch(documentsBegin())
    try {
      const response = await axios.delete(`${baseURL}/tenantProfile/documents/${documentId}`)
      dispatch(deleteTenantDocument())
      history.push('/application/tenant/documents')
      toast.success('Documents Successfully Deleted!')
      dispatch(startGetTenantProfile())
    } catch (error) {
      console.log(error.response)
      dispatch(documentsEnd())
    }
  }
}

// UPLOAD_ADDITIONAL_TENANT_DOCUMENT
export const startUploadAdditionalTenantDocument = (file, uploadOnly) => {
  return async(dispatch, getState) => {
    dispatch(documentsBegin())
    try {
      const config = { headers: { 'content-type': 'multipart/form-data' } }
      const formData = new FormData()
      formData.append('documentFile', file)
      const response = await axios.post(`${baseURL}/tenant/additional/`, formData, config)
      dispatch(uploadAdditionalTenantDocument())
      dispatch(startGetTenantProfile())
      if (!uploadOnly) {
        history.push('/application/tenant')
      }
      toast.success('Additional Document Successfully Uploaded!')
      dispatch(startGetDocumentList())
    } catch (error) {
      console.log(error.response)
      dispatch(documentsEnd())
    }
  }
}

// GET_TENANT_DOCUMENT_LIST
export const startGetDocumentList = () => {
  return async(dispatch, getState) => {
    dispatch(documentsBegin())
    try {
      const response = await axios.get(`${baseURL}/tenant/documents`)
      dispatch(getTenantDocumentList(response.data))
    } catch (error) {
      console.log(error.response)
      dispatch(documentsEnd())
    }
  }
}

// GET_LANDLORD_DOCUMENT_LIST
export const startGetLandlordDocumentList = () => {
  return async(dispatch, getState) => {
    dispatch(documentsBegin())
    try {
      const response = await axios.get(`${baseURL}/landlord/documents`)
      dispatch(getLandlordDocumentList(response.data))
    } catch (error) {
      console.log(error.response)
      dispatch(documentsEnd())
    }
  }
}

// UPLOAD_ADDITIONAL_LANDLORD_DOCUMENT
export const startUploadAdditionalLandlordDocument = (file) => {
  return async(dispatch, getState) => {
    dispatch(documentsBegin())
    try {
      const config = { headers: { 'content-type': 'multipart/form-data' } }
      const formData = new FormData()
      formData.append('documentFile', file)
      const response = await axios.post(`${baseURL}/landlord/additional/`, formData, config)
      await dispatch(startGetLandlordDocumentList())
      dispatch(uploadAdditionalLandlordDocument())
    } catch (error) {
      console.log(error.response)
      dispatch(documentsEnd())
    }
  }
}

// DOWNLOAD_ADDITIONAL_LANDLORD_DOCUMENT
export const startDownloadAdditionalLandlordDocument = (documentId) => {
  return async(dispatch, getState) => {
    dispatch(documentsBegin())
    try {
      const response = await axios({ url: `${baseURL}/landlordProfiles/additional/${documentId}`, method: 'GET', responseType: 'blob' })
      const a = document.createElement('a')
      const fileName = response.headers['content-disposition'].split('filename=')[1]
      a.download = decodeURI(fileName)
      a.href = window.URL.createObjectURL(new Blob([response.data]))
      document.body.append(a)
      a.click()
      await dispatch(startGetLandlordDocumentList())
      dispatch(downloadAdditionalLandlordDocument())
    } catch (error) {
      console.log(error.response)
      dispatch(documentsEnd())
    }
  }
}
