import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { startGetTemplateDocument } from '../redux/actions/documents'

const Footer = () => {
  const templateDocuments = useSelector(state => state.documents.templateDocuments)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!Object.keys(templateDocuments).includes('PRVC')) {
      dispatch(startGetTemplateDocument('PRVC'))
    }
  }, [])

  return (
    <footer className='footer p-0'>
      <div className='row px-3 px-lg-5 py-3 py-lg-0'>
        <div className='col-12 col-sm-4 py-0 py-lg-3 pl-3'>
          <div className='pt-3 pb-3 pb-lg-0 px-3 px-lg-5'>
            <p className='f-bold'>Contact</p>
            <div className='mb-2' style={{ fontSize: '17px' }}>
              <div className='mb-1'><a className='text-white' href='tel:+6017184612'>601-718-4612</a></div>
            </div>
          </div>
        </div>
        <div className='col-12 col-sm-4 py-0 py-lg-3'>
          <div className='pt-3 pb-3 pb-lg-0 px-3 px-lg-5'>
            <p className='f-bold'>Find Us on Social Media</p>
            <a className='mr-2' style={{ fontSize: '25px' }} href='https://www.facebook.com/mshomecorp' target='_blank' rel='noopener noreferrer'><span className='fab fa-facebook mr-2' style={{ minWidth: '15px' }}/></a>
            <a className='mr-2' style={{ fontSize: '25px' }} href='https://www.instagram.com/mshomecorp/' target='_blank' rel='noopener noreferrer'><span className='fab fa-instagram mr-2' style={{ minWidth: '15px' }}/></a>
            <a className='mr-2' style={{ fontSize: '25px' }} href='https://twitter.com/mshomecorp' target='_blank' rel='noopener noreferrer'><span className='fab fa-twitter mr-2' style={{ minWidth: '15px' }}/></a>
          </div>
        </div>
        <div className='col-12 col-sm-4 py-0 py-lg-3 pr-3'>
          <div className='pt-3 pb-3 pb-lg-0 px-3 px-lg-5'>
            <div className='mb-3'><a className='f-bold' href={templateDocuments['PRVC']} target='_blank' rel='noopener noreferrer'>Privacy Policy</a></div>
            <p className='mb-0' style={{ fontSize: '17px' }}>
              RAMP strives to provide online resources in a safe, secure manner that respects your privacy when you visit our site.</p>
          </div>
        </div>
      </div>
    </footer>
  )
}

Footer.propTypes = {

}

export default Footer
