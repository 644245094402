import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'

const PrivateLandloardEsignRoute = ({ component: Component, ...rest }) => {
  const isLoggedIn = useSelector(state => state.auth.isLoggedIn)
  const isLandlord = useSelector(state => state.auth.currentUser.isLandlord)
  const isEsignReady = useSelector(state => state.landlordApplication.isEsignReady)

  return (
    <Route {...rest} component={(props) => (
      isLoggedIn && isLandlord && isEsignReady ? (
        <Component {...props} />
      ) : (
        <Redirect to='/login' /> // Take them to their logged out home view
      )
    )} />
  )
}

PrivateLandloardEsignRoute.propTypes = {
  component: PropTypes.object
}

export default PrivateLandloardEsignRoute
