import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'

const PrivateTenantEsignRoute = ({ component: Component, ...rest }) => {
  const isLoggedIn = useSelector(state => state.auth.isLoggedIn)
  const isTenant = useSelector(state => state.auth.currentUser.isTenant)
  const isEsignReady = useSelector(state => state.tenantApplication.isEsignReady)
  const isProd = environment === 'production'

  return (
    <Route {...rest} component={(props) => (
      isLoggedIn && isTenant && isEsignReady ? !isProd && (
        <Component {...props} />
      ) : (
        <Redirect to='/login' /> // Take them to their logged out home view
      )
    )} />
  )
}

PrivateTenantEsignRoute.propTypes = {
  component: PropTypes.object
}

export default PrivateTenantEsignRoute
